<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          :options="searchOptions"
          :value="searchKey"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>

      <v-btn
        class=" mr-3 rounded-lg"
        color="primary"
        dark
        depressed
        :loading="statusRequest.value === 'loading-exportReportFile'"
        outlined
        @click="$emit('exportReportFile')"
      >
        <v-icon left>mdi-file-download-outline</v-icon>
        Xuất file
      </v-btn>

      <div class="action-row--col">
        <v-btn
          class="rounded-lg mr-3"
          color="primary"
          depressed
          @click="gotoDetailPage"
        >
          <v-icon left>mdi-plus</v-icon> Thêm mới
        </v-btn>
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div
      class="action-row d-flex align-center justify-space-between mt-4 gap-10 overflow-x-auto"
    >
      <div class="action-row--col align-center">
        <div class="action-row d-flex align-center justify-space-between gap-2">
          <template v-for="item in filterDueDateTemp">
            <v-btn
              v-if="item.count !== 0"
              :key="item.value"
              :color="item.active ? 'primary' : 'white'"
              elevation="0"
              height="40"
              class="font-weight-regular text-body-1 text-none"
              @click="selectDueDateFilter(item)"
            >
              {{ item.label }}

              <v-badge
                :color="
                  item.active
                    ? 'white'
                    : item.value === 0
                    ? 'red darken-1'
                    : 'grey lighten-3'
                "
                inline
              >
                <template v-slot:badge>
                  <span
                    :class="
                      item.active
                        ? 'primary--text'
                        : item.value === 0
                        ? 'text-white'
                        : 'text-black'
                    "
                  >
                    {{ item.count }}
                  </span>
                </template>
              </v-badge>
            </v-btn></template
          >
        </div>
      </div>

      <div class="action-row--col d-flex align-center ">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          btn-class="white rounded-lg mr-5 flex-shrink-0"
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
        <div class="text-body-2 flex-shrink-0">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          color="primary"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedShowFilterId: {
      type: Number
    },
    searchKey: {
      type: String
    },
    dueDateFilter: {
      type: Array
    }
  },
  data() {
    return {
      actionList: [{ id: "delete", text: "Xóa" }],
      searchOptions: [
        { type: "search", label: "Tìm kiếm" }
        // { type: "option/search", label: "Theo mã, tên SKU" },
        // { type: "serial/search", label: "Theo serial" }
      ],
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ],
      filterDueDateTemp: [
        {
          label: "Đã quá hạn",
          value: 0,
          count: 0,
          active: false
        },
        {
          label: "Đến hạn hôm nay",
          value: 1,
          count: 0,
          active: false
        },
        {
          label: "Đến hạn ngày mai",
          value: 2,
          count: 0,
          active: false
        }
      ]
    };
  },
  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    paginationInfo() {
      return this.$store.getters["INTERNAL_GUARANTEE_NOTE/paginationInfo"];
    },
    statusRequest() {
      return this.$store.getters["INTERNAL_REPAIR_NOTE/statusRequest"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    offBizDetail() {
      return this.$store.getters["INTERNAL_GUARANTEE_NOTE/offBizDetail"];
    },
    dueDateData() {
      return this.$store.getters["INTERNAL_GUARANTEE_NOTE/dueDate"];
    }
  },
  methods: {
    async gotoDetailPage() {
      this.offBizDetail.branch_id =
        this.user && this.user.branch_id ? this.user.branch_id : null;
      this.offBizDetail.assigned_to_id =
        this.user && this.user.id ? this.user.id : null;
      await this.$store.dispatch(
        "INTERNAL_GUARANTEE_NOTE/setOffBizDetailLocal",
        null
      );
      await this.$router.push({
        name: "not-sold-warehouse_internal-guarantee-notes-create"
      });
    },

    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    },

    selectDueDateFilter(val) {
      const targetItem = this.filterDueDateTemp.find(
        item => item.value === val.value
      );

      if (targetItem) {
        targetItem.active = !targetItem.active;
      }

      this.$emit(
        "updateDueDateFilter",
        this.filterDueDateTemp
          .filter(item => item.active)
          .map(item => item.value) ?? []
      );
    }
  },

  watch: {
    dueDateData: {
      handler(val) {
        this.filterDueDateTemp.forEach(item => {
          if (item.value === 0) {
            item.count = val.expired;
          } else if (item.value === 1) {
            item.count = val.due_today;
          } else if (item.value === 2) {
            item.count = val.due_tomorrow;
          }
        });
      },
      deep: true,
      immediate: true
    },

    dueDateFilter: {
      handler(val) {
        if (val.length) {
          this.filterDueDateTemp.forEach(item => {
            item.active = val.includes(item.value);
          });
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}

.gap-2 {
  gap: 8px;
}

.gap-10 {
  gap: 40px;
}
</style>
