var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.statusRequest.value === 'loading-getListOffBiz',"loading-text":"Đang tải dữ liệu","items":_vm.listOffBiz,"item-key":"id"},on:{"click:row":function($event){return _vm.viewDetail($event)}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.code)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}},{key:"item.serial.serial_number",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.serial.serial_number)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.serial.serial_number)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}},{key:"item.serial.product_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('div',{},[_vm._v(" "+_vm._s(item.serial.product_name)+" ")]),_c('div',{staticClass:"sub--text text-subtitle-2 font-weight-light grey--text text--darken-2",domProps:{"innerHTML":_vm._s(item.serial.option_name)}})])]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"86px"}},[(_vm.isDueDateExpired(item.due_date))?_c('div',{staticClass:"d-flex flex-col red--text"},[_c('span',[_vm._v(_vm._s(item.due_date))]),_c('span',[_vm._v("Đã quá hạn")])]):_c('span',[_vm._v(_vm._s(item.due_date))])])]}},{key:"item.step",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.findedStatus(_vm.REPAIR_GUARANTEE_STATUS, item.step).color,"small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.findedStatus(_vm.REPAIR_GUARANTEE_STATUS, item.step).label)+" ")])]}},{key:"item.selected_action",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.findedStatus(_vm.ACTION_STATUS, item.selected_action).color,"small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.findedStatus(_vm.ACTION_STATUS, item.selected_action).label)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }